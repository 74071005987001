<template>
  <div class="vx-row">
    <vs-popup title="TRAITEMENT DES VERRES" :active.sync="popupTraitementVerre">
      <div class="vx-row">
        <div class="vx-col w-full">
          <vs-input class="w-full mt-4" type="number" min="0" label="PHOTOGRAY"  name="PHOTOGRAY" v-model="prixPhotogray" />
        </div>
        <div class="vx-col w-full">
          <vs-input class="w-full mt-4" type="number" min="0" label="ANTI REFLET"  name="ANTI_REFLET" v-model="prixAntiReflet" />
        </div>
        <div class="vx-col w-full">
          <vs-input class="w-full mt-4" type="number" min="0" label="ANTI RAYURE"  name="ANTI_RAYURE" v-model="prixAntiRayure" />
        </div>
        <div class="vx-col w-full">
          <vs-input class="w-full mt-4" type="number" min="0" label="AUTRE TRAITEMENT"  name="AUTRE_TRAITEMENT" v-model="prixAutreTraitement" />
        </div>
      </div>
    </vs-popup>
    <vs-popup title="CONSOMMER PLAFON" :active.sync="popupConsommerPlafon">
      <div class="vx-row">
        <div class="vx-col w-full">
          <span>Ajuster les prix:</span>
        </div>
        <div class="vx-col md:w-1/2 mt-5">
          <vs-checkbox label="Prix monture" v-model="ajusterPrixMonture" class="w-full" > Prix monture </vs-checkbox>
        </div>
         <div class="vx-col md:w-1/2 mt-5">
          <vs-checkbox label="Prix verres" v-model="ajusterPrixVerre" class="w-full" > Prix verres </vs-checkbox>
        </div>
        <div class="vx-col w-full mt-5">
          <vs-select label="Majoration part client" autocomplete class="selectExample w-full select-large mt-4" v-model="partMajorerClient">
            <vs-select-item :key="index" :value="item.taux" :text="`${item.valeur}`" v-for="(item,index) in tauxMajorations" />
          </vs-select>
        </div>
      </div>
      <div class="vx-row">
          <div class="vx-col sm:w-2/3 w-full ml-auto">
            <vs-button class="mr-3 mb-2" @click="ConsommerPlafon()">VALIDER</vs-button>
            <vs-button color="warning" type="border" class="mb-2" @click="popupConsommerPlafon=false">ANNULER</vs-button>
          </div>
        </div>
    </vs-popup>
    <div  class="vx-col md:w-3/4">
      <vx-card id="corps-proforma">
        <!-- PDF Generator -->

        <vue-html2pdf
          :show-layout="false"
          :float-layout="true"
          :enable-download="false"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          :filename="`facture-N°${NumCmmd}-${assuran}-${entrepise}-${patient}`"
          :pdf-quality="2"
          :manual-pagination="false"
          pdf-format="a4"
          pdf-orientation="portrait"
          pdf-content-width="100%"

          ref="html2Pdf"
        >
          <section slot="pdf-content" class="pl-5 pr-5">
            <div class="vx-row pdf-item">
              <div class="vx-col w-full">
                <!-- INVOICE METADATA -->
                <!-- <div class="vx-row leading-loose">
                  <div class="vx-col w-1/2 mt-base">

                  </div>

                </div> -->

                <div class="vx-row" style="margin-top: 183px">
                  <div class="vx-col w-1/2">

                  </div>
                  <div class="vx-col w-1/2 text-center">
                    {{lieu_edition}} {{DateCommd | moment("calendar", "July 10 2011") }}
                  </div>
                </div>
                <div class="vx-row mt-5">
                  <div class="vx-col w-1/2">
                    <span class="font-bold">{{assuran}}</span>
                  </div>
                  <div class="vx-col w-1/2 text-center">
                    <span class="font-bold">{{entrepise}}</span>
                  </div>
                </div>
                <div class="vx-row mt-5">
                  <div class="vx-col w-full text-center">
                    <span>DETAIL FACTURE</span> <br>
                    PERIODE DU  {{DateDebut | moment("calendar", "July 10 2011") }} AU {{DateFin | moment("calendar", "July 10 2011") }}
                  </div>
                </div>
                <template>
                  <table class="w-full mt-5" cellpadding="0" cellspacing="0">
                    <thead>
                      <template v-if="montureClearName1!=''">
                        <tr>
                          <th class="text-center" style="border-bottom-width: 0px; padding: 3px;" colspan="12">
                            CLIENT
                          </th>
                        </tr>
                        <tr>
                          <th style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                            <span class="font-bold">N°</span>
                          </th>
                          <th style="border-bottom-width: 0px; border-right-width: 0px;" colspan="2">
                            <span class="font-bold">DATE</span>
                          </th>
                          <th style="border-bottom-width: 0px; border-right-width: 0px;" colspan="2">
                            <span class="font-bold">MATRICULE</span>
                          </th>
                          <th style="border-bottom-width: 0px; border-right-width: 0px;" colspan="2">
                            <span class="font-bold">ENTREPRISE</span>
                          </th>
                          <th class="text-center" style="border-bottom-width: 0px;" colspan="5">
                            <span class="font-bold">PATIENT</span>
                          </th>
                        </tr>
                        <tr>
                          <th style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                            <span class="font-semibold">{{NumCmmd}}</span>
                          </th>
                          <th style="border-bottom-width: 0px; border-right-width: 0px;" colspan="2">
                            <span class="font-semibold">{{DateCommd | moment("calendar", "July 10 2011") }}</span>
                          </th>
                          <th style="border-bottom-width: 0px; border-right-width: 0px;" colspan="2">
                            <span class="font-semibold">{{matricule}}</span>
                          </th>
                          <th style="border-bottom-width: 0px; border-right-width: 0px;" colspan="2">
                            <span class="font-semibold">{{entrepise}}</span>
                          </th>
                          <th class="text-center" style="border-bottom-width: 0px;" colspan="5">
                            <span class="font-semibold">{{patient}}</span>
                          </th>
                        </tr>
                        <tr>
                          <th style="border-bottom-width: 0px; padding:10px;" colspan="12">

                          </th>
                        </tr>
                        <tr>
                          <th class="text-center" style="border-bottom-width: 0px;" colspan="12">
                            <span class="font-bold">VERRES OPTIQUES</span>
                          </th>
                        </tr>
                        <tr>
                          <th style="border-bottom-width: 0px; border-right-width: 0px;" colspan="5">
                            <span class="font-bold">FOURNISSEUR: {{four_verre}}</span>
                          </th>
                          <th style="border-bottom-width: 0px; border-right-width: 0px;" colspan="3">
                            <span class="font-semibold">NOM COMMERCIAL: </span>
                          </th>
                          <th class="text-center" style="border-bottom-width: 0px; border-left-width: 0px;" colspan="3">
                            {{RefVerG1}}
                          </th>
                        </tr>
                        <tr>
                          <th class="text-center" style="border-bottom-width: 0px;" colspan="12">
                            <span class="font-semibold">PRESCRIPTION</span>
                          </th>
                        </tr>
                        <tr>
                          <th style="border-bottom-width: 0px; border-right-width: 0px;border-top-width: 0px;" colspan="1">

                          </th>
                          <th style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                            <span class="font-bold">SPHERE </span>
                          </th>
                          <th style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                            <span class="font-bold">CYLINDRE </span>
                          </th>
                          <th style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                            <span class="font-bold">AXE </span>
                          </th>
                          <th style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                            <span class="font-bold">ADDITION </span>
                          </th>
                          <th class="text-left" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="2">
                            <span class="font-semibold">QUANTITE</span>
                          </th>
                          <th class="text-left" style="border-bottom-width: 0px;" colspan="2">
                            <span class="font-semibold">PRIX HT</span>
                          </th>
                        </tr>
                        <tr>
                          <th style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                            <span class="font-bold">OEIL DROIT </span>
                          </th>
                          <th style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                            <span class="font-bold">{{SphVD}}</span>
                          </th>
                          <th style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                            <span class="font-bold">{{CylVD}}</span>
                          </th>
                          <th style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                            <span class="font-bold">{{AxeVD}}°</span>
                          </th>
                          <th style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                            <span class="font-bold">{{AddVD}}</span>
                          </th>
                          <th class="text-right" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="2">
                            <span class="font-bold">1</span>
                          </th>
                          <th class="text-center" style="border-bottom-width: 0px;" colspan="2">
                            <span class="font-semibold">{{moneyFormatter(PrixVerD1)}}</span>
                          </th>
                        </tr>
                        <tr>
                          <th style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                            <span class="font-bold">OEIL GAUCHE </span>
                          </th>
                          <th style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                            <span class="font-bold">{{SphVD}}</span>
                          </th>
                          <th style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                            <span class="font-bold">{{CylVD}}</span>
                          </th>
                          <th style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                            <span class="font-bold">{{AxeVD}}°</span>
                          </th>
                          <th style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                            <span class="font-bold">{{AddVD}}</span>
                          </th>
                          <th class="text-right" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="2">
                            <span class="font-bold">1</span>
                          </th>
                          <th class="text-center" style="border-bottom-width: 0px;" colspan="2">
                            <span class="font-semibold">{{moneyFormatter(PrixVerG1)}}</span>
                          </th>
                        </tr>
                        <tr>
                          <th style="border-bottom-width: 0px; padding:10px;" colspan="12">

                          </th>
                        </tr>
                        <tr>
                          <th class="text-center" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="5">
                            <span class="font-bold">TRAITEMENT DES VERRES</span>
                          </th>
                          <th class="text-left" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="2">
                            <span class="font-semibold">APPLICATION </span>
                          </th>
                          <th class="text-left" style="border-bottom-width: 0px;" colspan="2">
                            <span class="font-semibold">PRIX HT</span>
                          </th>
                        </tr>
                        <tr>
                          <th class="text-left" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="5">
                            <span class="font-semibold">PHOTOGRAY</span>
                          </th>
                          <th class="text-right" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="2">
                            <template v-if="prixPhotogray === 0">
                              -
                            </template>
                            <template v-else>
                              x
                            </template>
                          </th>
                          <th class="text-center" style="border-bottom-width: 0px;" colspan="2">
                            <span class="font-semibold">
                              <template v-if="prixPhotogray === 0">
                              -
                            </template>
                            <template v-else>
                              {{moneyFormatter(prixPhotogray)}}
                            </template>
                            </span>
                          </th>
                        </tr>

                        <tr>
                          <th class="text-left" style="border-top-width: 0px;border-bottom-width: 0px; border-right-width: 0px;" colspan="5">
                            <span class="font-semibold">ANTI REFLET</span>
                          </th>
                          <th class="text-right" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="2">
                            <template v-if="prixAntiReflet === 0">
                              -
                            </template>
                            <template v-else>
                              x
                            </template>
                          </th>
                          <th class="text-center" style="border-bottom-width: 0px;" colspan="2">
                            <span class="font-semibold">
                              <template v-if="prixAntiReflet === 0">
                                -
                              </template>
                              <template v-else>
                                {{moneyFormatter(prixAntiReflet)}}
                              </template>
                            </span>
                          </th>
                        </tr>
                        <tr>
                          <th class="text-left" style="border-top-width: 0px;border-bottom-width: 0px; border-right-width: 0px;" colspan="5">
                            <span class="font-semibold">ANTI RAYURE</span>
                          </th>
                          <th class="text-right" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="2">
                            <template v-if="prixAntiRayure === 0">
                                -
                              </template>
                              <template v-else>
                                x
                              </template>
                          </th>
                          <th class="text-center" style="border-bottom-width: 0px;" colspan="2">
                            <span class="font-semibold">
                              <template v-if="prixAntiRayure === 0">
                                -
                              </template>
                              <template v-else>
                                {{moneyFormatter(prixAntiRayure)}}
                              </template>
                            </span>
                          </th>
                        </tr>
                        <tr>
                          <th class="text-left" style="border-top-width: 0px;border-bottom-width: 0px; border-right-width: 0px;" colspan="5">
                            <span class="font-semibold">AUTRE</span>
                          </th>
                          <th class="text-right" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="2">
                            <template v-if="prixAutreTraitement === 0">
                                -
                              </template>
                              <template v-else>
                                x
                              </template>
                          </th>
                          <th class="text-center" style="border-bottom-width: 0px;" colspan="2">
                            <span class="font-semibold">
                              <template v-if="prixAutreTraitement === 0">
                                -
                              </template>
                              <template v-else>
                                {{moneyFormatter(prixAutreTraitement)}}
                              </template>
                            </span>
                          </th>
                        </tr>
                        <tr>
                          <th class="text-center" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="5">
                            <span class="font-bold">MONTURE</span>
                          </th>
                          <th class="text-left" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="2">
                            <span class="font-bold">MONTANT VERRES </span>
                          </th>
                          <th class="text-center" style="border-bottom-width: 0px; border-left-width: 0px;" colspan="2">
                            <span class="font-semibold">{{moneyFormatter(totalVerreProposition1)}}</span>
                          </th>
                        </tr>

                        <tr>
                          <th class="text-left" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="5">
                            <span class="font-bold">FOURNISSEUR: {{four_monture}}</span>
                          </th>
                          <th class="text-left" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="2">
                            <span class="font-semibold">QUANTITE </span>
                          </th>
                          <th class="text-left" style="border-bottom-width: 0px;" colspan="2">
                            <span class="font-semibold">PRIX HT</span>
                          </th>
                        </tr>
                        <tr>
                          <th class="text-center" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="5">
                            <span class="font-semibold">MARQUE: {{nom_marque_monture}}</span><br>
                            <span class="font-semibold">REFERENCE: {{reference_monture}}</span><br>
                            <span class="font-semibold">COULEUR: {{couleur_monture}}</span><br>
                            <span class="font-semibold">TAILLE: {{taille_monture}}</span><br>
                          </th>
                          <th class="text-left" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="2">
                            <span class="font-semibold"></span>
                          </th>
                          <th class="text-center" style="border-bottom-width: 0px;" colspan="2">
                            <span class="font-semibold">{{moneyFormatter(PrixMont1)}}</span>
                          </th>
                        </tr>
                        <tr>
                          <th class="text-right" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="7">
                            <span class="font-bold">TOTAL HT: </span>
                          </th>
                          <th class="text-center" style="border-bottom-width: 0px;" colspan="2">
                            <span class="font-bold">{{moneyFormatter(calculNet)}}</span>
                          </th>
                        </tr>
                        <tr>
                          <th class="text-left" style="border-bottom-width: 0px; border-right-width: 0px;border-left-width: 0px;" colspan="5">

                          </th>
                          <th class="text-left" style="border-bottom-width: 0px; border-left-width: 0px;border-right-width: 0px;" colspan="2">
                            <span class="font-bold">TICKET MODERATEUR </span>
                          </th>
                          <th class="text-center" style="border-bottom-width: 0px;border-left-width: 0px;border-right-width: 0px;" colspan="2">
                            <span class="font-bold">{{moneyFormatter(calculParClient)}}</span>
                          </th>
                        </tr>
                        <tr>
                          <th class="text-center" style="border-top-width: 0px;border-bottom-width: 0px;border-right-width: 0px;border-left-width: 0px;" colspan="12">

                          </th>
                        </tr>
                        <tr>
                          <th class="text-center" style="border-top-width: 0px;border-bottom-width: 0px;border-right-width: 0px;border-left-width: 0px;" colspan="12">

                          </th>
                        </tr>
                        <tr>
                          <th class="text-center" style="border-top-width: 0px;border-bottom-width: 0px;border-right-width: 0px;border-left-width: 0px;" colspan="12">

                          </th>
                        </tr>
                        <tr>
                          <th class="text-center" style="border-top-width: 0px;border-bottom-width: 0px;border-right-width: 0px;border-left-width: 0px;" colspan="12">

                          </th>
                        </tr>
                        <tr>
                          <th class="text-center" style="border-top-width: 0px;border-bottom-width: 0px;border-right-width: 0px;border-left-width: 0px;" colspan="12">

                          </th>
                        </tr>
                        <tr>
                          <th class="text-center" style="border-top-width: 0px;border-bottom-width: 0px;border-right-width: 0px;border-left-width: 0px;" colspan="5">

                          </th>
                          <th class="text-left" style="border-bottom-width: 0px;border-right-width: 0px;" colspan="2">
                            <span class="font-bold">PART ASSURANCE TTC </span>
                          </th>
                          <th class="text-center" style="border-bottom-width: 0px;" colspan="2">
                            <span class="font-bold">{{ moneyFormatter(calculParAssurance) }}</span>
                          </th>
                        </tr>
                        <tr>
                          <th class="text-center" style="border-top-width: 0px;border-bottom-width: 0px;border-right-width: 0px;border-left-width: 0px;" colspan="5">

                          </th>
                          <th class="text-left" style="border-bottom-width: 0px;border-left-width: 0px; padding: 10px;border-right-width: 0px;" colspan="4">
                            <span></span>
                          </th>
                        </tr>
                        <tr>
                          <th class="text-center" style="border-top-width: 0px;border-bottom-width: 0px;padding: 10px;border-right-width: 0px;border-left-width: 0px;" colspan="12">

                          </th>
                        </tr>
                        <tr>
                          <th colspan="12">
                            <span class="font-bold">ARRETE LA PRESENTE FACTURE A LA SOMME DE: {{NumberToLetter(calculParAssurance)}} FRANCS CFA</span>
                          </th>
                        </tr>
                      </template>
                    </thead>
                  </table>


                  <div class="vx-row mt-5" style="margin-bottom: 57px">
                    <div class="vx-col md:w-2/3">
                    </div>
                    <div class="vx-col md:w-1/3">
                      <p style="font-style: underline">L'OPTICIEN DIRECTEUR</p>
                    </div>
                  </div>

                </template>
              </div>
            </div>
          </section>
        </vue-html2pdf>

        <!-- PDF Generator end -->
        <div class="vx-row" ref="invoice_content">
          <div class="vx-col w-full">
            <!-- INVOICE METADATA -->

            <div class="vx-row" style="margin-top: 0px">
              <div class="vx-col w-1/2">

              </div>
              <div class="vx-col w-1/2 text-center">
                {{lieu_edition}} {{DateCommd | moment("calendar", "July 10 2011") }}
              </div>
            </div>
            <div class="vx-row mt-5">
              <div class="vx-col w-1/2">
                <span class="font-bold">{{assuran}}</span>
              </div>
              <div class="vx-col w-1/2 text-center">
                <span class="font-bold">{{entrepise}}</span>
              </div>
            </div>
            <div class="vx-row mt-5">
              <div class="vx-col w-full text-center">
                <span>DETAIL FACTURE</span> <br>
                PERIODE DU  {{DateDebut | moment("calendar", "July 10 2011") }} AU {{DateFin | moment("calendar", "July 10 2011") }}
              </div>
            </div>
            <template>
              <table class="w-full mt-5" cellpadding="0" cellspacing="0">
                <thead>
                  <template v-if="montureClearName1 !='' || RefVerG1 !='' || RefVerD1 !='' ">
                    <tr>
                      <th class="text-center" style="border-bottom-width: 0px; padding: 3px;" colspan="12">
                        CLIENT
                      </th>
                    </tr>
                    <tr>
                      <th style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                        <span class="font-bold">N°</span>
                      </th>
                      <th style="border-bottom-width: 0px; border-right-width: 0px;" colspan="2">
                        <span class="font-bold">DATE</span>
                      </th>
                      <th style="border-bottom-width: 0px; border-right-width: 0px;" colspan="2">
                        <span class="font-bold">MATRICULE</span>
                      </th>
                      <th style="border-bottom-width: 0px; border-right-width: 0px;" colspan="2">
                        <span class="font-bold">ENTREPRISE</span>
                      </th>
                      <th class="text-center" style="border-bottom-width: 0px;" colspan="5">
                        <span class="font-bold">PATIENT</span>
                      </th>
                    </tr>
                    <tr>
                      <th style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                        <span class="font-semibold">{{NumCmmd}}</span>
                      </th>
                      <th style="border-bottom-width: 0px; border-right-width: 0px;" colspan="2">
                        <span class="font-semibold">{{DateCommd | moment("calendar", "July 10 2011") }}</span>
                      </th>
                      <th style="border-bottom-width: 0px; border-right-width: 0px;" colspan="2">
                        <span class="font-semibold">{{matricule}}</span>
                      </th>
                      <th style="border-bottom-width: 0px; border-right-width: 0px;" colspan="2">
                        <span class="font-semibold">{{entrepise}}</span>
                      </th>
                      <th class="text-center" style="border-bottom-width: 0px;" colspan="5">
                        <span class="font-semibold">{{patient}}</span>
                      </th>
                    </tr>
                    <tr>
                      <th style="border-bottom-width: 0px; padding:10px;" colspan="12">

                      </th>
                    </tr>
                    <tr>
                      <th class="text-center" style="border-bottom-width: 0px;" colspan="12">
                        <span class="font-bold">VERRES OPTIQUES</span>
                      </th>
                    </tr>
                    <tr>
                      <th style="border-bottom-width: 0px; border-right-width: 0px;" colspan="5">
                        <span class="font-bold">FOURNISSEUR: {{four_verre}}</span>
                      </th>
                      <th style="border-bottom-width: 0px; border-right-width: 0px;" colspan="3">
                        <span class="font-semibold">NOM COMMERCIAL: </span>
                      </th>
                      <th class="text-center" style="border-bottom-width: 0px; border-left-width: 0px;" colspan="3">
                        {{RefVerG1}}
                      </th>
                    </tr>
                    <tr>
                      <th class="text-center" style="border-bottom-width: 0px;" colspan="12">
                        <span class="font-semibold">PRESCRIPTION</span>
                      </th>
                    </tr>
                    <tr>
                      <th style="border-bottom-width: 0px; border-right-width: 0px;border-top-width: 0px;" colspan="1">

                      </th>
                      <th style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                        <span class="font-bold">SPHERE </span>
                      </th>
                      <th style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                        <span class="font-bold">CYLINDRE </span>
                      </th>
                      <th style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                        <span class="font-bold">AXE </span>
                      </th>
                      <th style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                        <span class="font-bold">ADDITION </span>
                      </th>
                      <th class="text-left" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="2">
                        <span class="font-semibold">QUANTITE</span>
                      </th>
                      <th class="text-left" style="border-bottom-width: 0px;" colspan="2">
                        <span class="font-semibold">PRIX HT</span>
                      </th>
                    </tr>
                    <tr>
                      <th style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                        <span class="font-bold">OEIL DROIT </span>
                      </th>
                      <th style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                        <span class="font-bold">{{SphVD}}</span>
                      </th>
                      <th style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                        <span class="font-bold">{{CylVD}}</span>
                      </th>
                      <th style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                        <span class="font-bold">{{AxeVD}}°</span>
                      </th>
                      <th style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                        <span class="font-bold">{{AddVD}}</span>
                      </th>
                      <th class="text-right" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="2">
                        <span class="font-bold">1</span>
                      </th>
                      <th class="text-center" style="border-bottom-width: 0px;" colspan="2">
                        <span class="font-semibold">{{moneyFormatter(PrixVerD1)}}</span>
                      </th>
                    </tr>
                    <tr>
                      <th style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                        <span class="font-bold">OEIL GAUCHE </span>
                      </th>
                      <th style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                        <span class="font-bold">{{SphVD}}</span>
                      </th>
                      <th style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                        <span class="font-bold">{{CylVD}}</span>
                      </th>
                      <th style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                        <span class="font-bold">{{AxeVD}}°</span>
                      </th>
                      <th style="border-bottom-width: 0px; border-right-width: 0px;" colspan="1">
                        <span class="font-bold">{{AddVD}}</span>
                      </th>
                      <th class="text-right" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="2">
                        <span class="font-bold">1</span>
                      </th>
                      <th class="text-center" style="border-bottom-width: 0px;" colspan="2">
                        <span class="font-semibold">{{moneyFormatter(PrixVerG1)}}</span>
                      </th>
                    </tr>
                    <tr>
                      <th style="border-bottom-width: 0px; padding:10px;" colspan="12">

                      </th>
                    </tr>
                    <tr>
                      <th class="text-center" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="5">
                        <span class="font-bold">TRAITEMENT DES VERRES</span>
                      </th>
                      <th class="text-left" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="2">
                        <span class="font-semibold">APPLICATION </span>
                      </th>
                      <th class="text-left" style="border-bottom-width: 0px;" colspan="2">
                        <span class="font-semibold">PRIX HT</span>
                      </th>
                    </tr>
                    <tr>
                      <th class="text-left" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="5">
                        <span class="font-semibold">PHOTOGRAY</span>
                      </th>
                      <th class="text-right" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="2">
                        <template v-if="prixPhotogray === 0">
                          -
                        </template>
                        <template v-else>
                          x
                        </template>
                      </th>
                      <th class="text-center" style="border-bottom-width: 0px;" colspan="2">
                        <span class="font-semibold">
                          <template v-if="prixPhotogray === 0">
                          -
                        </template>
                        <template v-else>
                          {{moneyFormatter(prixPhotogray)}}
                        </template>
                        </span>
                      </th>
                    </tr>

                    <tr>
                      <th class="text-left" style="border-top-width: 0px;border-bottom-width: 0px; border-right-width: 0px;" colspan="5">
                        <span class="font-semibold">ANTI REFLET</span>
                      </th>
                      <th class="text-right" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="2">
                        <template v-if="prixAntiReflet === 0">
                          -
                        </template>
                        <template v-else>
                          x
                        </template>
                      </th>
                      <th class="text-center" style="border-bottom-width: 0px;" colspan="2">
                        <span class="font-semibold">
                          <template v-if="prixAntiReflet === 0">
                            -
                          </template>
                          <template v-else>
                            {{moneyFormatter(prixAntiReflet)}}
                          </template>
                        </span>
                      </th>
                    </tr>
                    <tr>
                      <th class="text-left" style="border-top-width: 0px;border-bottom-width: 0px; border-right-width: 0px;" colspan="5">
                        <span class="font-semibold">ANTI RAYURE</span>
                      </th>
                      <th class="text-right" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="2">
                        <template v-if="prixAntiRayure === 0">
                            -
                          </template>
                          <template v-else>
                            x
                          </template>
                      </th>
                      <th class="text-center" style="border-bottom-width: 0px;" colspan="2">
                        <span class="font-semibold">
                          <template v-if="prixAntiRayure === 0">
                            -
                          </template>
                          <template v-else>
                            {{moneyFormatter(prixAntiRayure)}}
                          </template>
                        </span>
                      </th>
                    </tr>
                    <tr>
                      <th class="text-left" style="border-top-width: 0px;border-bottom-width: 0px; border-right-width: 0px;" colspan="5">
                        <span class="font-semibold">AUTRE</span>
                      </th>
                      <th class="text-right" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="2">
                        <template v-if="prixAutreTraitement === 0">
                            -
                          </template>
                          <template v-else>
                            x
                          </template>
                      </th>
                      <th class="text-center" style="border-bottom-width: 0px;" colspan="2">
                        <span class="font-semibold">
                          <template v-if="prixAutreTraitement === 0">
                            -
                          </template>
                          <template v-else>
                            {{moneyFormatter(prixAutreTraitement)}}
                          </template>
                        </span>
                      </th>
                    </tr>
                    <tr>
                      <th class="text-center" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="5">
                        <span class="font-bold">MONTURE</span>
                      </th>
                      <th class="text-left" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="2">
                        <span class="font-bold">MONTANT VERRES </span>
                      </th>
                      <th class="text-center" style="border-bottom-width: 0px; border-left-width: 0px;" colspan="2">
                        <span class="font-semibold">{{moneyFormatter(totalVerreProposition1)}}</span>
                      </th>
                    </tr>

                    <tr>
                      <th class="text-left" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="5">
                        <span class="font-bold">FOURNISSEUR: {{four_monture}}</span>
                      </th>
                      <th class="text-left" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="2">
                        <span class="font-semibold">QUANTITE </span>
                      </th>
                      <th class="text-left" style="border-bottom-width: 0px;" colspan="2">
                        <span class="font-semibold">PRIX HT</span>
                      </th>
                    </tr>
                    <tr>
                      <th class="text-center" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="5">
                        <span class="font-semibold">MARQUE: {{nom_marque_monture}}</span><br>
                        <span class="font-semibold">REFERENCE: {{reference_monture}}</span><br>
                        <span class="font-semibold">COULEUR: {{couleur_monture}}</span><br>
                        <span class="font-semibold">TAILLE: {{taille_monture}}</span><br>
                      </th>
                      <th class="text-left" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="2">
                        <span class="font-semibold"></span>
                      </th>
                      <th class="text-center" style="border-bottom-width: 0px;" colspan="2">
                        <span class="font-semibold">{{moneyFormatter(PrixMont1)}}</span>
                      </th>
                    </tr>
                    <tr>
                      <th class="text-right" style="border-bottom-width: 0px; border-right-width: 0px;" colspan="7">
                        <span class="font-bold">TOTAL HT: </span>
                      </th>
                      <th class="text-center" style="border-bottom-width: 0px;" colspan="2">
                        <span class="font-bold">{{moneyFormatter(calculNet)}}</span>
                      </th>
                    </tr>
                    <tr>
                      <th class="text-left" style="border-bottom-width: 0px; border-right-width: 0px;border-left-width: 0px;" colspan="5">

                      </th>
                      <th class="text-left" style="border-bottom-width: 0px; border-left-width: 0px;border-right-width: 0px;" colspan="2">
                        <span class="font-bold">TICKET MODERATEUR </span>
                      </th>
                      <th class="text-center" style="border-bottom-width: 0px;border-left-width: 0px;border-right-width: 0px;" colspan="2">
                        <span class="font-bold">{{moneyFormatter(calculParClient)}}</span>
                      </th>
                    </tr>
                    <tr>
                      <th class="text-center" style="border-top-width: 0px;border-bottom-width: 0px;border-right-width: 0px;border-left-width: 0px;" colspan="12">

                      </th>
                    </tr>
                    <tr>
                      <th class="text-center" style="border-top-width: 0px;border-bottom-width: 0px;border-right-width: 0px;border-left-width: 0px;" colspan="12">

                      </th>
                    </tr>
                    <tr>
                      <th class="text-center" style="border-top-width: 0px;border-bottom-width: 0px;border-right-width: 0px;border-left-width: 0px;" colspan="12">

                      </th>
                    </tr>
                    <tr>
                      <th class="text-center" style="border-top-width: 0px;border-bottom-width: 0px;border-right-width: 0px;border-left-width: 0px;" colspan="12">

                      </th>
                    </tr>
                    <tr>
                      <th class="text-center" style="border-top-width: 0px;border-bottom-width: 0px;border-right-width: 0px;border-left-width: 0px;" colspan="12">

                      </th>
                    </tr>
                    <tr>
                      <th class="text-center" style="border-top-width: 0px;border-bottom-width: 0px;border-right-width: 0px;border-left-width: 0px;" colspan="5">

                      </th>
                      <th class="text-left" style="border-bottom-width: 0px;border-right-width: 0px;" colspan="2">
                        <span class="font-bold">PART ASSURANCE TTC </span>
                      </th>
                      <th class="text-center" style="border-bottom-width: 0px;" colspan="2">
                        <span class="font-bold">{{ moneyFormatter(calculParAssurance) }}</span>
                      </th>
                    </tr>
                    <tr>
                      <th class="text-center" style="border-top-width: 0px;border-bottom-width: 0px;border-right-width: 0px;border-left-width: 0px;" colspan="5">

                      </th>
                      <th class="text-left" style="border-bottom-width: 0px;border-left-width: 0px; padding: 10px;border-right-width: 0px;" colspan="4">
                        <span></span>
                      </th>
                    </tr>
                    <tr>
                      <th class="text-center" style="border-top-width: 0px;border-bottom-width: 0px;padding: 10px;border-right-width: 0px;border-left-width: 0px;" colspan="12">

                      </th>
                    </tr>
                    <tr>
                      <th colspan="12">
                        <span class="font-bold">ARRETE LA PRESENTE FACTURE A LA SOMME DE: {{NumberToLetter(calculParAssurance)}} FRANCS CFA</span>
                      </th>
                    </tr>
                  </template>
                </thead>
              </table>


              <div class="vx-row mt-5" style="margin-bottom: 57px">
                <div class="vx-col md:w-2/3">
                </div>
                <div class="vx-col md:w-1/3">
                  <p style="font-style: underline">L'OPTICIEN DIRECTEUR</p>
                </div>
              </div>

            </template>
          </div>
        </div>
      </vx-card>
    </div>
    <div class="vx-col md:w-1/4">
      <vx-card>
        <vs-select label="Prescripteur" disabled="true" autocomplete class="selectExample w-full select-large mt-4" v-model="Idprescripteur" @change="goToNewPrescripteur">
          <vs-select-item :key="index" :value="item._id" :text="`${item.NomPresc} ${item.PrenomPresc}`" v-for="(item,index) in prescripteurs" />
        </vs-select>
        <div class="mt-4">
          <vs-input class="w-full mt-4" type="text" label="Lieu d'edition"  name="lieu_edition" v-model="lieu_edition" />
        </div>
        <div class="mt-4">
          <label class="text-sm">Date</label>
          <flat-pickr v-model="DateCommd" :config="{ dateFormat: 'd F Y' }" class="w-full" name="dateProformat" />
        </div>
        <div class="mt-4">
          <vs-input class="w-full mt-4" type="text" label="N°"  name="NumCmmd" v-model="NumCmmd" />
        </div>
        <div class="mt-4">
          <label class="text-sm">Debut</label>
          <flat-pickr v-model="DateDebut" :config="{ dateFormat: 'd F Y'}" class="w-full" name="dateProformat" />
        </div>
        <div class="mt-4">
          <label class="text-sm">Fin</label>
          <flat-pickr v-model="DateFin" :config="{ dateFormat: 'd F Y'}" class="w-full" name="dateProformat" />
        </div>
        <div class="mt-4">
          <vs-input class="w-full mt-4" type="text" label="Fournisseur verre"  name="four_verre" v-model="four_verre" />
        </div>
        <vs-select label="Fournisseur monture" autocomplete class="selectExample w-full select-large mt-4" v-model="four_monture">
          <vs-select-item :key="index" :value="item.Nom" :text="`${item.Nom}`" v-for="(item,index) in fournisseurs" />
        </vs-select>
        <div class="mt-4">
          <vs-input class="w-full mt-4" type="number" label="Prix verre droit"  name="PrixVerD1" v-model="PrixVerD1"/>
        </div>
        <div class="mt-4">
          <vs-input class="w-full mt-4" type="number" label="Prix verre gauche"  name="PrixVerG1" v-model="PrixVerG1"/>
        </div>
        <div class="mt-4">
          <vs-input class="w-full mt-4" type="number" label="Prix monture"  name="PrixMont1" v-model="PrixMont1"/>
        </div>
        <div class="mt-4">
          <vs-input class="w-full mt-4" type="number" label="Remise"  name="RemiseCom" v-model="RemiseCom" @change="ConvertRemise" />
        </div>
        <div class="mt-4">
          <vs-input class="w-full mt-4" disabled="true" type="number" label="Plafon"  name="plafon" v-model="plafon" />
        </div>
        <div class="mt-4">
          <vs-button color="primary" class="w-full" @click.stop="popupTraitementVerre=true">TRAITEMENT DES VERRES</vs-button>
        </div>
        <div class="mt-4">
          <vs-button color="primary" class="w-full" @click.stop="popupConsommerPlafon=true">CONSOMMER PLAFON</vs-button>
        </div>

        <div class="mt-4">
          <vs-button color="primary" class="w-full" @click="generateReport()">IMPRIMER FACTURE</vs-button>
        </div>
        <div class="mt-4">
          <vs-button color="primary" class="w-full" @click="saveFacture()">ENREGISTRER</vs-button>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import VueHtml2pdf from 'vue-html2pdf'
import jsPDF from 'jspdf'
/* import html2canvas from 'html2canvas' */

export default {
  components: {
    vSelect,
    flatPickr,
    VueHtml2pdf
  },
  data () {
    return {
      activeUserInfos: null,
      RefCmmd: null,
      NumCmmd: null,
      RefOrd: null,
      lieu_edition: 'ABIDJAN LE',
      DateDebut: null,
      DateFin: null,
      popupTraitementVerre: false,
      prixPhotogray: 0,
      prixAntiReflet: 0,
      prixAntiRayure: 0,
      prixAutreTraitement: 0,

      ajusterPrixMonture: true,
      ajusterPrixVerre: false,
      partMajorerClient: 0,
      tauxMajorations:
      [
        {taux: 5, valeur: '5%'},
        {taux: 10, valeur: '10%'},
        {taux: 15, valeur: '15%'},
        {taux: 20, valeur: '20%'},
        {taux: 25, valeur: '25%'},
        {taux: 30, valeur: '30%'},
        {taux: 35, valeur: '35%'},
        {taux: 40, valeur: '40%'},
        {taux: 45, valeur: '45%'},
        {taux: 50, valeur: '50%'}
      ],

      popupConsommerPlafon: false,

      nom_marque_monture: '',
      reference_monture: '',
      couleur_monture: '',
      taille_monture: '',

      n_cmmd: null,
      four_verre: null,
      four_monture: null,

      client: {
        Civilite: '',
        Nom: '',
        Prenoms: '',
        Adresse : {
          mobile: '',
          tel: ''
        }
      },
      IdOfficine: null,
      IdClient: null,
      Idprescripteur: null,
      IdUser: null,
      IdPartenaire: null,
      DatePrescrit: null,
      DateCommd: null,
      SphVD: '',
      SphVG: '',
      CylVD: '',
      CylVG: '',
      AxeVD: '',
      AxeVG: '',
      AddVD: '',
      AddVG: '',
      EdVD: '',
      EdVG: '',
      HdVD: '',
      HdVG: '',
      EcartVD1: '',
      EcartVD2: '',
      EcartVG1: '',
      EcartVG2: '',
      HtVD1: '',
      HtVD2: '',
      HtVG1: '',
      HtVG2: '',
      DctrVD1: '',
      DctrVD2: '',
      DctrVG1: '',
      DctrVG2: '',
      DVOVD: '',
      DVOVG: '',
      DLVD: '',
      DLVG: '',
      IdMont1: null,
      libelleMontureClient: '',
      libelleMontureClient2: '',
      OrigineMont1: '',
      PrixMont1: 0,
      RefVerG1: '',
      PrixVerG1: 0,
      RefVerD1: '',
      PrixVerD1: 0,
      Suplement1: '',
      IdMont2: null,
      OrigineMont2: '',
      PrixMont2: 0,
      RefVerG2: '',
      PrixVerG2: 0,
      RefVerD2: '',
      PrixVerD2: 0,
      Suplement2: '',
      Remarque: '',
      TotalFactBrut: 0,
      RemiseCom: 0,
      TotalFactNet: 0,
      PartClient: 0,
      PartAssu: 0,
      PECharge: false,
      CmdeTSalleMontage: false,
      CmdeAuFourVerre: false,
      CmdeMontageFin: false,
      ComdeLivreClient: false,

      propositions: [],
      popupActive: false,
      popupActivePescripteur: false,
      popupActivePriseEncharge: false,
      popupActiveClient: false,
      origineMonture: [
        {
          text: 'Stock',
          value: 'stock'
        },
        {
          text: 'Monture Client',
          value: 'monture_client'
        },
        {
          text: 'Autre',
          value: 'autre'
        }
      ],
      showMontureStockSelect: true,
      showMontureClientInput: false,
      showMontureStockSelectP2: true,
      showMontureClientInputP2: false,
      showCatalogueSelectVerD1: false,
      showCatalogueSelectVerG1: false,
      showCatalogueSelectVerG2: false,
      showCatalogueSelectVerD2: false,
      catalogueVerreG1: '',
      catalogueVerreD1: '',
      prevRoute: null,

      IdCenterHospi:'',
      NomPresc: '',
      PrenomPresc:'',
      Ristourne: false,
      taux:0,
      TelPresc: '',
      Mobile: '',
      FaxPresc: '',
      AdrPresc: '',
      EmailPresc: '',
      RemarqueP:'',

      RaisonSoc: '',
      AdrCli: '',
      MailCli: '',
      TelCli1: '',
      TelCli2: '',
      TelCli3: '',
      MobCli1: '',
      MobCli2: '',
      FaxCli: '',

      montureClearName1: '',
      montureClearName2: '',

      active: false,

      /* PDF Config params */
      showlayout: 'false',
      floatlayout: 'true',
      enabledownload: 'false',
      previewmodal: 'true',
      filename: 'Proforma',
      pdfquality: '2',
      manualpagination: 'false',
      pdfformat: 'a4',
      pdforientation: 'landscape',
      pdfcontentwidth: '100%',

      /* Societe */
      NomSociete:'',
      RegistreCom:'',

      /* Assurance */
      Nom_Abrege:'',
      Nom_Complet:'',
      adresse:'',
      pays:'',
      telephone:'',
      mobile:'',
      fax:'',
      Email:'',
      Site:'',
      RegistreComA:'',
      ComptCont:'',
      ComptBanq:'',
      DelaiRegl: null,

      /* Prise en charge */
      RefPEC: null,
      IdSociete: null,
      IdAssurance: null,
      RefCommande: '',
      NumPolice: '',
      Assurer: '',
      NumPatient: '',
      Patient: '',
      NumSalarie: '',
      Salarie: '',
      TickMont: '',
      TickVerr: '',
      PlafMont: '',
      PlafVerr: '',
      TickForf: '',
      PlafForf: '',
      QteMont: '',
      QteVerr: '',
      QtePair: '',

      /* info prise en charge */
      entrepise: '',
      assuran: '',
      matricule: '',
      assur: '',
      patient: '',
      ticket: 0,
      ticketM: 0,
      ticketV: 0,
      plafon: 0,
      plafonM: 0,
      plafonV: 0,
      qtePaire: 0,

      /* Montant facture net */
      factureNet: 0
    }
  },
  computed: {
    clientsData () {
      return this.$store.state.client.clients
    },
    prescripteurs () {
      return this.$store.state.prescripteur.prescripteurs
    },
    centreHospitaliers () {
      return this.$store.state.centre_hospitalier.centre_hospitaliers
    },
    Categorie_articleData () {
      return this.$store.state.categorie_article.categorie_articles
    },
    getCategorieMontureId () {
      const categorie = this.$store.state.categorie_article.categorie_articles.find((c) => c.Libelle_Categ === 'Monture')
      return categorie ? categorie._id : null
    },
    totalVerreProposition1 () {
      return (+this.PrixVerG1 + +this.PrixVerD1)
    },
    totalVerreProposition2 () {
      return (+this.PrixVerG2 + +this.PrixVerD2)
    },
    PaysData () {
      return this.$store.state.pays.pays
    },
    Societes () {
      return this.$store.state.societe.societes
    },
    assuranceData () {
      return this.$store.state.assurance.assurances
    },
    fournisseurs () {
      return this.$store.state.fournisseur.fournisseurs
    },
    calculNet () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.factureNet = Math.round(((+this.PrixMont1 + +this.PrixVerG1 + +this.PrixVerD1) +  (+this.PrixMont2 + +this.PrixVerG2 + +this.PrixVerD2) + (+this.prixPhotogray + +this.prixAntiReflet + +this.prixAntiRayure + +this.prixAutreTraitement)) - +this.RemiseCom)
      return this.factureNet
    },
    calculParClient () {
      if (this.factureNet >= this.plafon) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.PartClient = +this.factureNet - +this.plafon
      } else {
        // eslint-disable-next-line no-lonely-if
        if (this.ticket <= 100) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.PartClient = Math.round(((+this.factureNet * this.ticket) / 100))
        } else {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.PartClient = this.ticket
        }

      }

      return this.PartClient
    },
    calculParAssurance () {
      /* const montontAppliqueTicketModerateur =
      const parAssTheorique = this.qtePaire === 2 ? this.factureNet : (+this.PrixMont1 + +this.PrixVerG1 + +this.PrixVerD1) */

      if (this.factureNet >= this.plafon) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.PartAssu = this.plafon
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.PartAssu = Math.round((+this.factureNet - +this.PartClient))
      }
      return this.PartAssu
    }
  },
  methods: {
    /* conversion nombre en lettre */
    Unite (nombre) {
      let unite = ''
      switch (nombre) {
      case 0:
        unite = 'zéro'
        break
      case 1:
        unite = 'un'
        break
      case 2:
        unite = 'deux'
        break
      case 3:
        unite = 'trois'
        break
      case 4:
        unite = 'quatre'
        break
      case 5:
        unite = 'cinq'
        break
      case 6:
        unite = 'six'
        break
      case 7:
        unite = 'sept'
        break
      case 8:
        unite = 'huit'
        break
      case 9:
        unite = 'neuf'
        break
      }

      return unite
    },
    Dizaine (nombre) {
      let dizaine = ''
      switch (nombre) {
      case 10:
        dizaine = 'dix'
        break
      case 11:
        dizaine = 'onze'
        break
      case 12:
        dizaine = 'douze'
        break
      case 13:
        dizaine = 'treize'
        break
      case 14:
        dizaine = 'quatorze'
        break
      case 15:
        dizaine = 'quinze'
        break
      case 16:
        dizaine = 'seize'
        break
      case 17:
        dizaine = 'dix-sept'
        break
      case 18:
        dizaine = 'dix-huit'
        break
      case 19:
        dizaine = 'dix-neuf'
        break
      case 20:
        dizaine = 'vingt'
        break
      case 30:
        dizaine = 'trente'
        break
      case 40:
        dizaine = 'quarante'
        break
      case 50:
        dizaine = 'cinquante'
        break
      case 60:
        dizaine = 'soixante'
        break
      case 70:
        dizaine = 'soixante-dix'
        break
      case 80:
        dizaine = 'quatre-vingt'
        break
      case 90:
        dizaine = 'quatre-vingt-dix'
        break
      }
      return dizaine
    },
    NumberToLetter (nombre) {
      let quotient = 0, reste = 0
      let numberToLetter = ''
      //__________________________________

      if (nombre.toString().replace(/ /gi, '').length > 15) {
        return 'dépassement de capacité'
      }
      if (isNaN(nombre.toString().replace(/ /gi, ''))) {
        return 'Nombre non valide'
      }

      const nb = parseFloat(nombre.toString().replace(/ /gi, ''))
      if (Math.ceil(nb) !== nb) {
        return  'Nombre avec virgule non géré.'
      }

      const n = nb.toString().length
      switch (n) {
      case 1:
        numberToLetter = this.Unite(nb)
        break
      case 2:
        if (nb > 19) {
          quotient = Math.floor(nb / 10)
          reste = nb % 10
          if (nb < 71 || (nb > 79 && nb < 91)) {
            if (reste === 0) numberToLetter = this.Dizaine(quotient * 10)
            if (reste === 1) numberToLetter = `${this.Dizaine(quotient * 10)  }-et-${  this.Unite(reste)}`
            if (reste > 1) numberToLetter = `${this.Dizaine(quotient * 10)  }-${  this.Unite(reste)}`
          } else numberToLetter = `${this.Dizaine((quotient - 1) * 10)  }-${  this.Dizaine(10 + reste)}`
        } else numberToLetter = this.Dizaine(nb)
        break
      case 3:
        quotient = Math.floor(nb / 100)
        reste = nb % 100
        if (quotient === 1 && reste === 0) numberToLetter = 'cent'
        if (quotient === 1 && reste !== 0) numberToLetter = `cent ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.Unite(quotient)  } cents`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.Unite(quotient)  } cent ${  this.NumberToLetter(reste)}`
        break
      case 4 :
        quotient = Math.floor(nb / 1000)
        reste = nb - (quotient * 1000)
        if (quotient === 1 && reste === 0) numberToLetter = 'mille'
        if (quotient === 1 && reste !== 0) numberToLetter = `mille ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } mille`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } mille ${  this.NumberToLetter(reste)}`
        break
      case 5 :
        quotient = Math.floor(nb / 1000)
        reste = nb - (quotient * 1000)
        if (quotient === 1 && reste === 0) numberToLetter = 'mille'
        if (quotient === 1 && reste !== 0) numberToLetter = `mille ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } mille`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } mille ${  this.NumberToLetter(reste)}`
        break
      case 6 :
        quotient = Math.floor(nb / 1000)
        reste = nb - (quotient * 1000)
        if (quotient === 1 && reste === 0) numberToLetter = 'mille'
        if (quotient === 1 && reste !== 0) numberToLetter = `mille ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } mille`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } mille ${  this.NumberToLetter(reste)}`
        break
      case 7:
        quotient = Math.floor(nb / 1000000)
        reste = nb % 1000000
        if (quotient === 1 && reste === 0) numberToLetter = 'un million'
        if (quotient === 1 && reste !== 0) numberToLetter = `un million ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } millions`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } millions ${  this.NumberToLetter(reste)}`
        break
      case 8:
        quotient = Math.floor(nb / 1000000)
        reste = nb % 1000000
        if (quotient === 1 && reste === 0) numberToLetter = 'un million'
        if (quotient === 1 && reste !== 0) numberToLetter = `un million ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } millions`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } millions ${  this.NumberToLetter(reste)}`
        break
      case 9:
        quotient = Math.floor(nb / 1000000)
        reste = nb % 1000000
        if (quotient === 1 && reste === 0) numberToLetter = 'un million'
        if (quotient === 1 && reste !== 0) numberToLetter = `un million ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } millions`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } millions ${  this.NumberToLetter(reste)}`
        break
      case 10:
        quotient = Math.floor(nb / 1000000000)
        reste = nb - (quotient * 1000000000)
        if (quotient === 1 && reste === 0) numberToLetter = 'un milliard'
        if (quotient === 1 && reste !== 0) numberToLetter = `un milliard ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } milliards`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } milliards ${  this.NumberToLetter(reste)}`
        break
      case 11:
        quotient = Math.floor(nb / 1000000000)
        reste = nb - (quotient * 1000000000)
        if (quotient === 1 && reste === 0) numberToLetter = 'un milliard'
        if (quotient === 1 && reste !== 0) numberToLetter = `un milliard ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } milliards`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } milliards ${  this.NumberToLetter(reste)}`
        break
      case 12:
        quotient = Math.floor(nb / 1000000000)
        reste = nb - (quotient * 1000000000)
        if (quotient === 1 && reste === 0) numberToLetter = 'un milliard'
        if (quotient === 1 && reste !== 0) numberToLetter = `un milliard ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } milliards`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } milliards ${  this.NumberToLetter(reste)}`
        break
      case 13:
        quotient = Math.floor(nb / 1000000000000)
        reste = nb - (quotient * 1000000000000)
        if (quotient === 1 && reste === 0) numberToLetter = 'un billion'
        if (quotient === 1 && reste !== 0) numberToLetter = `un billion ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } billions`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } billions ${  this.NumberToLetter(reste)}`
        break
      case 14:
        quotient = Math.floor(nb / 1000000000000)
        reste = nb - (quotient * 1000000000000)
        if (quotient === 1 && reste === 0) numberToLetter = 'un billion'
        if (quotient === 1 && reste !== 0) numberToLetter = `un billion ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } billions`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } billions ${  this.NumberToLetter(reste)}`
        break
      case 15:
        quotient = Math.floor(nb / 1000000000000)
        reste = nb - (quotient * 1000000000000)
        if (quotient === 1 && reste === 0) numberToLetter = 'un billion'
        if (quotient === 1 && reste !== 0) numberToLetter = `un billion ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } billions`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } billions ${  this.NumberToLetter(reste)}`
        break
      }//fin switch
      /*respect de l'accord de quatre-vingt*/
      if (numberToLetter.substr(numberToLetter.length - 'quatre-vingt'.length, 'quatre-vingt'.length) === 'quatre-vingt') numberToLetter = `${numberToLetter  }s`

      return numberToLetter.toUpperCase()
    },
    /* fin conversion nombre en lettre */

    /* calcul du ticket moderateur */


    ConsommerPlafon () {
      const ecart = +this.plafon - +this.factureNet
      const part_du_client = Math.round(((this.partMajorerClient * ecart) / 100))

      if (this.ajusterPrixMonture === true && this.ajusterPrixVerre === true) {
        const quart = Math.round((ecart / 4))
        const moitier = Math.round((ecart / 2))

        const pc_quart = Math.round((part_du_client / 4))
        const pc_moitier = Math.round((part_du_client / 2))

        this.PrixVerG1 = +this.PrixVerG1 + +quart + +pc_quart
        this.PrixVerD1 = +this.PrixVerD1 + +quart + +pc_quart
        this.PrixMont1 = this.PrixMont1 + moitier + +pc_moitier

        this.popupConsommerPlafon = false
      } else if (this.ajusterPrixMonture === true) {
        const part_du_client = Math.round(((this.partMajorerClient * ecart) / 100))
        this.PrixMont1 = this.PrixMont1 + ecart + +part_du_client

        this.popupConsommerPlafon = false

      } else if (this.ajusterPrixVerre === true) {
        const moitier = Math.round((ecart / 2))
        const pc_moitier = Math.round((part_du_client / 2))

        this.PrixVerG1 = +this.PrixVerG1 + +moitier + +pc_moitier
        this.PrixVerD1 = +this.PrixVerD1 + +moitier + +pc_moitier

        this.popupConsommerPlafon = false
      }

      /* if (this.factureNet < this.plafon) {

      } else {
        this.popupConsommerPlafon = false
        this.$vs.dialog({
          type: 'alert',
          color: 'warning',
          title: 'Alert',
          text: 'Impossible plafon déjà consommer',
          buttonAccept: 'true'
        })
      } */


    },

    ConvertRemise () {
      if (this.RemiseCom <= 100) {
        this.RemiseCom = Math.round((((+this.PrixMont1 + +this.PrixVerG1 + +this.PrixVerD1) +  (+this.PrixMont2 + +this.PrixVerG2 + +this.PrixVerD2)) * this.RemiseCom) / 100)
      }
    },

    dowloadPdf () {
      const doc = new jsPDF()

      /* const canvasElement = document.createElement('canvas') */
      /* html2canvas(this.$refs.invoice_content, {canvas: canvasElement}).then(() => {
        doc.save('test.pdf')
      }) */

      const contentHtml = this.$refs.invoice_content.innerHTML

      console.log(doc)
      doc.html(contentHtml)
      doc.save('sample.pdf')
    },
    generateReport () {

      this.$vs.loading({
        type: 'sound'
      })

      setTimeout(() => {
        this.$vs.loading.close()
      }, 20000)

      this.$refs.html2Pdf.generatePdf()

    },

    moneyFormatter (montant) {
      /* Formatage du calcul */
      const val = (montant / 1).toFixed(2).replace('.', ',')

      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },

    saveFacture () {
      const payload = {
        IdOfficine: this.IdOfficine,
        IdUser: this.IdUser,
        IdClient: this.IdClient,
        matricule: this.matricule,
        patient: this.patient,
        IdAssurance: this.IdAssurance,
        IdSociete: this.IdSociete,
        RefCmmd: this.RefCmmd,
        NumCmmd: this.NumCmmd,
        RefPEC: this.RefPEC,
        Idprescripteur: this.Idprescripteur,
        DatePrescrit: this.DatePrescrit,
        DateCommd: this.DateCommd,
        SphVD: this.SphVD,
        SphVG: this.SphVG,
        CylVD: this.CylVD,
        CylVG: this.CylVG,
        AxeVD: this.AxeVD,
        AxeVG: this.AxeVG,
        AddVD: this.AddVD,
        AddVG: this.AddVG,
        EdVD: this.EdVD,
        EdVG: this.EdVG,
        HdVD: this.HdVD,
        HdVG: this.HdVG,
        EcartVD1: this.EcartVD1,
        EcartVD2: this.EcartVD2,
        EcartVG1: this.EcartVG1,
        EcartVG2: this.EcartVG2,
        HtVD1: this.HtVD1,
        HtVD2: this.HtVD2,
        HtVG1: this.HtVG1,
        HtVG2: this.HtVG2,
        DctrVD1: this.DctrVD1,
        DctrVD2: this.DctrVD2,
        DctrVG1: this.DctrVG1,
        DctrVG2: this.DctrVG2,
        DVOVD: this.DVOVD,
        DVOVG: this.DVOVG,
        DLVD: this.DLVD,
        DLVG: this.DLVG,
        IdMont1: this.IdMont1,
        OrigineMont1: this.OrigineMont1,
        PrixMont1: this.PrixMont1,
        libelleMontureClient1: this.libelleMontureClient,
        RefVerG1: this.RefVerG1,
        PrixVerG1: this.PrixVerG1,
        RefVerD1: this.RefVerD1,
        PrixVerD1: this.PrixVerD1,
        Suplement1: this.Suplement1,
        Remarque: this.Remarque,
        TotalHT: this.factureNet,
        RemiseCom: this.RemiseCom,
        PartClient: this.PartClient,
        PartAssu: this.PartAssu,
        fourVerre: this.four_verre,
        fourMonture: this.four_monture,
        prixPhotogray: this.prixPhotogray,
        prixAntiReflet: this.prixAntiReflet,
        prixAntiRayure: this.prixAntiRayure,
        prixAutreTraitement: this.prixAutreTraitement

      }

      this.$vs.loading({
        type: 'sound'
      })

      this.$store.dispatch('facturation/traitementFacture', payload)
        .then(() => {
          this.$vs.notify({
            title: 'Commande traiter !',
            text: 'success !!',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'success'
          })
          this.$router.push('/facturation/vente').catch((err) => { console.log(err) })
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.$vs.loading.close()
        })

    },
    getMarqueName (id) {
      const singleMarque = this.$store.getters['marque/getMarqueById'](id)
      return singleMarque ? singleMarque.Libelle_marq : ''
    },
    getArticleMonture (categorie) {
      const montures = this.$store.getters['stock/getArticleByCategorieArticle'](categorie)
      return montures.length ? montures : []
    },
    RefVerD1InfoPut () {
      this.showCatalogueSelectVerD1 = false
    },
    RefVerD2InfoPut () {
      this.showCatalogueSelectVerD2 = false
    },
    RefVerG1InfoPut () {
      this.showCatalogueSelectVerG1 = false
    },
    RefVerG2InfoPut () {
      this.showCatalogueSelectVerG2 = false
    },
    OrdonnanceValue (refOrd) {

      const ordonnance = this.client.ordonnances.find((o) => o.RefOrd === refOrd)
      this.SphVD = ordonnance.OdVlSph
      this.CylVD = ordonnance.OdVlCyl
      this.AxeVD = ordonnance.OdVlAxe
      this.AddVD = ordonnance.OdAddition
      this.SphVG = ordonnance.OgVlSph
      this.CylVG = ordonnance.OgVlCyl
      this.AxeVG = ordonnance.OgVlAxe
      this.AddVG = ordonnance.OgAddition
    },

    PriseEnChargeValue (RefPEC) {
      const pec = this.client.priseEnCharges.find((p) => p.RefPEC === RefPEC)
      /* console.log(`PEC: ${pec.IdSociete}`) */
      this.IdAssurance = pec.IdAssurance
      this.IdSociete = pec.IdSociete
      const societe = this.Societes.find((s) => s._id === pec.IdSociete)
      const ass = this.assuranceData.find((a) => a._id === pec.IdAssurance)
      /* console.log(`societe: ${societe.NomSociete}`) */

      this.entrepise = societe.NomSociete
      this.assuran = ass.Nom_Abrege
      this.matricule = pec.NumSalarie
      this.assur = pec.Assurer
      this.patient = pec.Patient
      this.ticket = pec.TickForf
      this.ticketM = pec.TickMont
      this.ticketV = pec.TickVerr
      this.plafon = pec.PlafForf
      this.plafonM = pec.PlafMont
      this.plafonV = pec.PlafVerr
      this.qtePaire = pec.QtePair
    },

    OrigineMontureFunction (origine) {
      if (origine === 'stock') {
        this.showMontureStockSelect = true
        this.showMontureClientInput = false

      }
      if (origine === 'monture_client' || origine === 'autre') {
        this.showMontureClientInput = true
        this.showMontureStockSelect = false
      }

    },
    OrigineMontureProposition2 (origine) {
      if (origine === 'stock') {
        this.showMontureStockSelectP2 = true
        this.showMontureClientInputP2 = false
      }
      if (origine === 'monture_client' || origine === 'autre') {
        this.showMontureClientInputP2 = true
        this.showMontureStockSelectP2 = false
      }
    },
    setMonturePrice1 (id) {
      const selectMonture = this.$store.state.stock.articles.find((m) => m._id === id)
      this.PrixMont1 = selectMonture.PrixVenteTTC
      this.montureClearName1 = `${selectMonture.Etiquette} | ${this.getMarqueName(selectMonture.IdMarque)} ${selectMonture.Modele} ${selectMonture.Couleur}`

      this.nom_marque_monture = this.getMarqueName(selectMonture.IdMarque)
      this.reference_monture = selectMonture.Modele
      this.couleur_monture = selectMonture.Couleur
      this.taille_monture = `${selectMonture.Taille[0]['Hauteur']}-${selectMonture.Taille[0]['Nez']}-${selectMonture.Taille[0]['Longeur']}`
    },
    setMonturePrice2 (id) {
      const selectMonture = this.$store.state.stock.articles.find((m) => m._id === id)
      this.PrixMont2 = selectMonture.PrixVenteTTC
      this.montureClearName2 = `${selectMonture.Etiquette} | ${this.getMarqueName(selectMonture.IdMarque)} ${selectMonture.Modele} ${selectMonture.Couleur}`
    },
    goToNewPrescripteur () {
      if (this.Idprescripteur === 'new_prescripteur') {
        this.popupActivePescripteur = true
        this.Idprescripteur = null
      }
    },
    PriseEnChargeNouveau () {
      if (this.RefPEC === 'new_prise_en_charge') {
        this.popupActivePriseEncharge = true
        this.RefPEC = null
      } else {
        this.PriseEnChargeValue()
      }
    },
    NouveauClient () {
      if (this.IdClient === 'new_client') {
        this.popupActiveClient = true
        this.IdClient = null
      } else {
        this.getClientById(this.IdClient)
      }
    },

    activeUserInfo () {
      this.activeUserInfos = JSON.parse(localStorage.getItem('userInfo'))
      this.IdOfficine = this.activeUserInfos.IdOfficine
      this.IdUser = this.activeUserInfos._id

      /*  this.getClientsOfficine(this.IdOfficine) */
    },
    getClientsOfficine (id) {
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('client/getClientsOfficine', id)
        .then((response) => {
          console.log(response.data)
        })
        .catch((error) => { console.log(error) })
        .finally(() => {
          this.$vs.loading.close()
        })
    },
    getClientById () {
      this.$vs.loading({
        type: 'sound'
      })
      this.$store.dispatch('client/getClientById', this.$route.params.clientId)
        .then((resp) => {
          this.client = resp.data
          this.IdClient = this.client._id
          this.IdOfficine = this.client.IdOfficine

          const commande = this.client.commandes.find((c) => c.RefCmmd === this.$route.params.RefCmmd)

          this.RefCmmd = commande.RefCmmd
          this.NumCmmd = commande.RefCmmd
          this.RefPEC = commande.RefPEC
          this.Idprescripteur = commande.Idprescripteur
          this.IdUser = commande.IdUser
          this.IdPartenaire = commande.IdPartenaire
          this.DatePrescrit = commande.DatePrescrit
          this.DateCommd = commande.DateCommd
          this.SphVD = commande.SphVD
          this.SphVG = commande.SphVG
          this.CylVD = commande.CylVD
          this.CylVG = commande.CylVG
          this.AxeVD = commande.AxeVD
          this.AxeVG = commande.AxeVG
          this.AddVD = commande.AddVD
          this.AddVG = commande.AddVG
          this.EdVD = commande.EdVD
          this.EdVG = commande.EdVG
          this.HdVD = commande.HdVD
          this.HdVG = commande.HdVG
          this.EcartVD1 = commande.EcartVD1
          this.EcartVD2 = commande.EcartVD2
          this.EcartVG1 = commande.EcartVG1
          this.EcartVG2 = commande.EcartVG2
          this.HtVD1 = commande.HtVD1
          this.HtVD2 = commande.HtVD2
          this.HtVG1 = commande.HtVG1
          this.HtVG2 = commande.HtVG2
          this.DctrVD1 = commande.DctrVD1
          this.DctrVD2 = commande.DctrVD2
          this.DctrVG1 = commande.DctrVG1
          this.DctrVG2 = commande.DctrVG2
          this.DVOVD = commande.DVOVD
          this.DVOVG = commande.DVOVG
          this.DLVD = commande.DLVD
          this.DLVG = commande.DLVG
          this.IdMont1 = commande.IdMont1
          this.OrigineMont1 = commande.OrigineMont1
          this.libelleMontureClient = commande.libelleMontureClient
          this.PrixMont1 = commande.PrixMont1
          this.RefVerG1 = commande.RefVerG1
          this.PrixVerG1 = commande.PrixVerG1
          this.RefVerD1 = commande.RefVerD1
          this.PrixVerD1 = commande.PrixVerD1
          this.Suplement1 = commande.Suplement1
          this.IdMont2 = commande.IdMont2
          this.OrigineMont2 = commande.OrigineMont2
          this.libelleMontureClient2 = commande.libelleMontureClient2
          this.PrixMont2 = commande.PrixMont2
          this.RefVerG2 = commande.RefVerG2
          this.PrixVerG2 = commande.PrixVerG2
          this.RefVerD2 = commande.RefVerD2
          this.PrixVerD2 = commande.PrixVerD2
          this.Suplement2 = commande.Suplement2
          this.Remarque = commande.Remarque
          this.RemiseCom = commande.RemiseCom
          this.PartClient = commande.PartClient
          this.PartAssu = commande.PartAssu
          /* this.IdClient = this.commande. */

          this.PriseEnChargeValue(this.RefPEC)

          if (this.IdMont1 !== null) {
            this.setMonturePrice1(this.IdMont1)
          }
          if (this.IdMont2 !== null) {
            this.setMonturePrice2(this.IdMont2)
          }
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.$vs.loading.close()
        })
    },
    getPays () {
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('pays/getPays')
        .then(() => { })
        .catch((err) => { console.log(err) })
        .finally(() => {
          this.$vs.loading.close()
        })
    },
    getSocietes () {
      this.$store.dispatch('societe/getSocietes')
        .then(() => {
        })
        .catch((err) => { console.log(err) })
    },
    getAssurances () {
      this.$store.dispatch('assurance/getAssurances')
        .then(() => {

        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.$vs.loading.close()
        })
    },
    getPrescripteurs () {
      this.$store.dispatch('prescripteur/getPrescripteurs')
        .then(() => {

        })
        .catch(err => { console.log(err) })
    },
    getCentreHospitalier () {
      this.$store.dispatch('centre_hospitalier/getCentreHospitalier')
        .then(() => {

        })
        .catch((err) => { console.log(err) })
    },
    getStocks () {
      this.$store.dispatch('stock/getStocks')
        .then(() => {

        })
        .catch((err) => { console.log(err) })
    },
    getCategorieArticles () {
      this.$store.dispatch('categorie_article/getCategorieArticles')
        .then(() => {

        })
        .catch(err => {
          console.error(err)
        })
    },
    getMarques () {
      this.$store.dispatch('marque/getMarques')
        .then(() => {
        })
        .catch(err => {
          console.error(err)
        })
    },
    getCommandeClients () {
      this.$store.dispatch('client/getCommandeClients')
        .then(() => {
        })
        .catch(err => {
          console.error(err)
        })
    },
    getFournisseurs () {
      this.$store.dispatch('fournisseur/getFournisseurs').catch(err => { console.error(err) })
    }
  },
  created () {
    this.getPrescripteurs()
    this.getCentreHospitalier()
    this.getStocks()
    this.getCategorieArticles()
    this.getMarques()
    this.getPays()
    this.getSocietes()
    this.getAssurances()
    this.getCommandeClients()
    this.activeUserInfo()
    this.getFournisseurs()
    this.getClientById()
  }
}
</script>

<style scoped>
.not-data-table{
  display: none;
}

/* table {
  border:   1px solid black;
} */
th, td {
  border: 1px solid black;
  padding: 3px;
}
</style>
